body {
  /* margin: 1rem auto; */
  /* max-width: 800px; */
  background-color: #fff;
  color: #000;
  --button-text: #000;
  --button-bg: transparent;
  transition: background-color 0.3s ease;
}

.dark span.navbar-toggler-icon {
  background: none !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
  color: cornflowerblue;
  font-size: 24px;
  margin-top: 10px;
}

.switcher.pl-3 select{
  cursor:pointer;
}
 
.lang-choose {
  appearance:none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='26' viewBox='0 0 24 24' width='26' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 8px;
  /* border: 1px solid #dfdfdf; */
  /* border-radius: 2px; */
  /* margin-right: 2rem; */
  /* padding: 1rem; */
  /* padding-right: 2rem; */
}

.dark .lang-choose {
  
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='26' viewBox='0 0 24 24' width='26' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  
}

/* nav-icon-5 */ .nav-icon-5 { width: 35px; height: 30px; margin: 10px 10px; position: relative; cursor: pointer; display: inline-block; } /* Animation for lines */ .line1, .line2, .line3 { transition: transform 0.3s ease, opacity 0.3s ease; } .nav-icon-5.open .line1 { transform: rotate(45deg) translate(5px, 5px); } .nav-icon-5.open .line2 { opacity: 0; } .nav-icon-5.open .line3 { transform: rotate(-45deg) translate(5px, -5px); } /* Menu styling */ .menu { background-color: #333; color: white; padding: 10px; } .menu ul { list-style-type: none; padding: 0; } .menu li { margin: 5px 0; } .menu a { color: white; text-decoration: none; }
 

.dark .nav-ico-hamb, .dark .nav-ico-x{
  color: cornflowerblue;
}

span.navbar-toggler-icon {
  background: none !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 100% !important;
  font-size: 24px;
  margin-top: 10px;
}

.page-header a.active{
  color: black !important;
}

.dark .page-header a.active{
  color: cornflowerblue !important;

}

.page-header a.nav-link:hover, .lang-active:hover{
  border-bottom: 2px solid #000;
  transition: all 0.25s ease-in;
}

.dark .page-header a.nav-link:hover, .dark .lang-active:hover{
  border-bottom: 2px solid cornflowerblue;
  transition: all 0.25s ease-in;
}

.reduced-motion div.modal-dialog, .reduced-motion div.modal-content, .reduced-motion div.modal-body{
  background-color: #000000 !important;
  color: whitesmoke !important;
}


.reduced-motion div.modal-dialog, .reduced-motion div.modal-content, div.reduced-motion .modal-body, div.dark.reduced-motion .modal-body,
.system div.modal-dialog, .system-motion div.modal-content, div.system .modal-body, div.dark.system .modal-body,
.dark div.modal-body, .dark .div.modal-content,
.dark .modal-content, .system .modal-content, .reduced-motion .modal-content
{
  background-color: #000000 !important;
  color: whitesmoke !important;
}

.fade.favorite-modal.system-modal.modal.show .modal-content{
  padding-right: 110px !important;
  bottom: 46px !important;

}

.old .system-modal-favorite div.modal-dialog{
  background-color: darkgray !important;
}

.modal-body.motion-modal-body{
  margin-right:100px !important;
   }
   div.motion-modal-favorite .modal-dialog{
    bottom: 46px !important;
  }

.about-page {

  animation: none !important;
  background-image: url('./../../about-view/img/bg.jpg') !important;
  background-size: 100% 100%;
}

.dark .about-page {

  animation: none !important;
  background-image: url('./../../about-view/img/bg-dark.jpg') !important;
  background-size: 100% 100%;
}

.old .formbold-builder-form.themeOne form {
  background-color: lightslategray !important;
}

.old .form-heading,
.old .contact-form-tip {
  color: whitesmoke;
}

.old .label-required,
.old .form-asterisk {
  color: rgb(255, 223, 44);
}

.old .dropdown-item {
  background-color: #879db3;
}

.old .dropdown-menu.show .dropdown-item:hover {
  background-color: #a2a6aa !important;
}

.old .dropdown-menu.show .dropdown-item {
  background-color: #bcc5cf !important;
}

.old .close-notification-container {
  background-color: lightslategray;
}

.old .notification-more-link {
  color: rgb(91, 122, 153) !important;
}

.old .notification {
  background-color: whitesmoke;
}

.old button.info-sound-click-button::after {
  color: whitesmoke;
}

.old button.info-sound-click-button:hover::after {
  color: white;
  transition: all 0.15s ease-in;
}

.old .m-auto.pe-5.ps-5.fade-in-up-on-scroll.send-input.inline-flex.cursor-pointer.items-center.justify-center.rounded.border.border-primary.bg-primary.px-8.py-2.text-base.font-medium.text-white.btn.btn-primary {
  background-color: #151414 !important;
}

.old .m-auto.pe-5.ps-5.fade-in-up-on-scroll.send-input.inline-flex.cursor-pointer.items-center.justify-center.rounded.border.border-primary.bg-primary.px-8.py-2.text-base.font-medium.text-white.btn.btn-primary:hover {
  background-color: #262525 !important;
}

.old .impressum-main-heading,
.old a.impressum-links {
  color: #7ca7d2 !important;
}

.old .about-page {

  animation: none !important;
  background-image: url('./../../about-view/img/bg-old.jpg') !important;
  background-size: 100% 100%;
}

.about-block,
.dark .about-block {
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 30px !important;
}

.reduced-motion .aboutAnimation {

  animation: none !important;
  background-image: #424242 !important;
}

  .reduced-motion .logo-pulse
  {
    animation: none !important;
    border-radius: 50%;
    width: 20em;
  }

  .reduced-motion .profile-picture{
  animation: none !important;
    
  }

  .reduced-motion .fa-envelope:hover,
  .reduced-motion .fa-skype:hover,
  .reduced-motion .fa-linkedin:hover,
  .reduced-motion .fa-twitter:hover,
  .reduced-motion .fa-github:hover,
  .reduced-motion .fa-instagram:hover,
  .reduced-motion .fa-arrow-up-right:hover,
  .reduced-motion .fa-medium:hover,
  .reduced-motion .fa-dev:hover {
    transition: none !important;
    translate: 0px 0px !important;
  }

  .reduced-motion .icon-style {
    animation: none !important;
  }

  

  .main-footer.reduced-motion .sidebar-toggler,
  .footer-404.reduced-motion .sidebar-toggler{
    animation: none !important;
  }

  
  .reduced-motion .sidebar-pulse{
  animation: none !important;

}

.about-page p {
  color: #000;
}

.about-block {
  background-color: #e2e1e1;
  border-radius: 14px;
}

.dark .logo {
  content: url("./../../../../public/dark-logo.svg");
}

.dark.navbar-expand-xl .navbar-nav a.nav-link:hover,
.dark.navbar-expand-xl .navbar-nav a.nav-cases:hover {
  color: cornflowerblue !important;
}

.navbar-expand-xl .navbar-nav a.nav-link:hover,
.navbar-expand-xl .navbar-nav a.nav-cases:hover {
  color: black !important;
}

.dark .logo-pulse,
.old .logo-pulse {
  content: url("./../../main-view/img/logo512-dark.png");
  
}

.theme-options-nav-container .themes-links{
  text-align: right;
}

.profile-picture {
  content: url('./../img/yevhenii_airapetian_profile_picture.webp');
}

.dark .profile-picture {
  content: url("./../img/profile-picture-dark.png");
  /* border-radius: 59% !important; */
}

.old .profile-picture {
  content: url("./../img/old-profile-picture.gif");
  /* border-radius: 59% !important; */
}



.favorite-modal .modal-header {
  display: none;
}


.dark .dark-modal-body,
.dark .popup {
  background-color: black !important;
  color: whitesmoke !important;
}

.dark .dark-modal-button {
  background-color: #424242 !important;
  color: #529fcc !important;
}

.light-modal-button {
  background-color: #529fcc !important;
  color: white !important;
}

.new-visitor-button {
  width: 80px;
  border-radius: 40px;
}

div.popup {
  height: 140px !important;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
}

.dark .whats-new-info {
  background-color: #000;
}

.dark .whats-new-link {
  color: #529fcc;
}

div.popup a {
  color: black;
}

.popup,
.popup p {
  background-color: #529fcc;
  color: white;
  padding: 32px 0px 42px 0px
}

.favorite-modal .modal-dialog,
.popup {
  display: block !important;
  background: transparent !important;
  position: fixed !important;
  bottom: -35px !important;
  left: 0 !important;
  right: 0 !important;
  width: 96% !important;
  height: 100px !important;
}

.new-visitor-button {
  background-color: transparent !important;
  top: 15px !important;
  right: 0 !important;
}


.delete-modal .modal-dialog {
  display: block !important;
  background: transparent !important;
  position: fixed !important;
  top: 30% !important;
  left: 0 !important;
  right: 0 !important;
  width: 96% !important;
  height: 100px !important;
}

.got-it-button {
  position: absolute;
  top: 10px;
  right: 16px;
  width: max-content;
}


body.dark {
  background-color: #424242;
  color: #fff;
  --button-text: #fff;
  --button-bg: transparent;
  transition: background-color 0.3s ease;
}

body.old {
  background-color: rgb(99, 96, 96);
  color: #fff;
  --button-text: #fff;
  --button-bg: transparent;
  transition: background-color 0.3s ease;
}

.old .projects-main-heading,
.about-heading,
.nerdy-blog-link,
.blog-note,
.r3play-headings,
a.deployed-link,
.r3play-slider-hint,
.poke-link {
  color: rgb(124, 167, 210) !important;
}

.old .projects-carousel-caption,
.old .carousel-caption {
  background-color: lightslategray;
}

nav.page-header {
  background-color: #529fcc;
  color: hsl(0, 0%, 100%);
  width: 100%;

}

.old nav.page-header {
  background-color: #7b929f;

  color: hsl(0, 0%, 100%);
  width: 100%;

}

.old .main-footer,
.old .footer-404,
.old div.switcher select {
  background-color: #7b929f;

}

.old h1.r3play-headings {
  color: #7b929f;
}

nav.page-header.dark {
  background-color: black;
  color: #fff;
}

html,
body,
#root {
  height: 100%;
}

.toggle_btn {
  background-color: var(--button-bg);
  color: var(--button-text);
  cursor: pointer;
  border: none;
}


* {
  font-family: "Montserrat", sans-serif;
}

.page-header {
  font-size: 1.2em;

  /* background-color: #529fcc; */

}

/* .download-de, .download-en, .download-uk{
    width:20px;
} */

.logo:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);

  transition: transform 0.3s ease-out, padding-right 0.3s ease-out, border-bottom 0.3s ease-out, padding-bottom 0.3s ease-out, color 0.3s ease-out;
}

div.switcher select {
  width: fit-content !important;
  height: 44px;
  background-color: #529fcc;
  color: black;
}

.dropdown-menu {
  background-color: #f3f0f0 !important;
  padding: 0 !important;

}

.navbar-expand-xl .navbar-nav .nav-link {
  color: white;
}

#nav-dropdown {
  color: white
}

.dropdown-item {
  padding: 2px 0 16px 10px !important;
}

.dropdown-item:hover {
  background-color: #529fcc !important;
}


.nav-cases {
  text-decoration: none;
  padding: 10px 25px 0 0;
  color: black;

}


.nav-lang-cases:hover {
  padding-left: 10px;
  transition: all 0.2s ease-in;
}

.nav-cases:hover {
  color: white;
  padding-left: 10px;
  transition: all 0.2s ease-in;
}

.fa-globe {
  padding-left: 14px;
}

button.toggle_btn {
  /* padding-left:0px; */
  margin-top: 3px;
}

.moon {
  /* width:17px; */
  /* height:24px; */
  font-size: 24px;
  margin-left: 10px;

}

.sun {
  /* width:27px; */
  /* height:24px; */
  font-size: 24px;
  margin-left: 10px;

}

div.new-info {
  padding: 10px 10px;
  color: #000;
}

.dark div.new-info {
  padding: 10px 10px;
  color: whitesmoke;
}

.notification {
  display: block;
  border-radius: 20px;
  text-align: center;
  width: 300px;
  height: fit-content;
  padding: 0 0 10px 0;
  position: fixed;
  top: 44vh !important;
  right: 0;
  z-index: 40;
  background-color: #529fcc;
  border-top: 1px solid hsl(189, 10%, 86%);
  color: white;
  border-bottom: 1px solid hsl(189, 10%, 86%);
  border-left: 1px solid hsl(189, 10%, 86%);
  border-right: 1px solid hsl(189, 10%, 86%);
}

.more-info-dialog-links,
.dark .more-info-dialog-links {
  text-decoration: none;
}

.more-info-dialog-links:hover {
  text-decoration: underline;
}

.dark span.notification-more-link {
  color: #529fcc !important;
}

.dark span.notification-more-link:hover {
  color: gold !important;
  text-decoration: underline;

}

button.info-sound-click-button::after {
  content: "—";
}

button.info-sound-click-button {
  color: #000 !important;
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
  right: 10px;
  top: 8px;

}

button.info-sound-click-button:hover {

  background-color: transparent;


}

/* button.info-sound-click-button::after{
  content: "—";
}

.notification-more::after{
  content: "—";
} */

.close-notification-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 40px;
  padding: 20px;
  background-color: #fff;
}

.dark .close-notification-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 40px;
  padding: 20px;
  background-color: #529fcc;
}

.dark span.notification-more {
  color: whitesmoke !important;
}

span.notification-more-link {
  color: whitesmoke !important;
}

span.notification-more-link:hover {
  color: rgb(0, 0, 0) !important;
}

.bell-calm {
  color: #000 !important;
}

.dark .bell-calm {
  color: #fff !important;
}

.dark .bell-active {
  color: #529fcc !important;
}

.bell-active {
  color: #ffd43b !important;
}


.dark .notification {
  display: block;
  text-align: center;
  width: 300px;
  height: fit-content;
  padding: 0 0 10px 0;
  position: fixed;
  top: 64vh;
  right: 0;
  z-index: 40;
  background-color: #000;
  color: whitesmoke;
}

@media screen and (max-width:600px) {

  div.modal-body.dark-modal-body {
    height: 140px;
    width: 100% !important;
  }

  .popup button.light-modal-button.got-it-button.new-visitor-button {
    top: 0 !important;
  }




  .system div.modal-body, .reduced-motion div.modal-body{
    background-color: #000 !important;

  }

  .dark .modal-content, .system .modal-content, .reduced-motion .modal-content {
    background-color: #000 !important;
    width: 100% !important;
  }

  div.popup {
    background-color: #529fcc !important;
  }

  .dark div.modal-body {
    width: 70% !important;
  }

  div.modal-body {
    height: 140px;
    width: 70% !important;
    /* background-color: #fff; */
  }

  .system div.modal-body, .reduced-motion div.modal-body{
    background-color: #000 !important;

  }

    .reduced-motion div.modal-dialog, .reduced-motion div.modal-content, div.reduced-motion .modal-body, div.dark.reduced-motion .modal-body,
  .system div.modal-dialog, .system-motion div.modal-content, div.system .modal-body, div.dark.system .modal-body,
  .dark .div.modal-body, .dark .div.modal-content{
    background-color: #000000 !important;
    color: whitesmoke !important;
  }

  
  .old div.modal-dialog, .old div.modal-content, .old .modal-body{
    background-color: darkgray !important;
  }

  .dark div.popup p,
  div.popup p {
    width: 90%;
    margin: 0 auto;
    padding-top: 10px !important;
  }

  div.popup,
  div.popup p {

    padding: 26px 0px 20px 0px
  }

  .popup a {
    color: #529fcc;
  }

  div.popup {
    height: 140px !important;
  }


  .popup button.light-modal-button.got-it-button.new-visitor-button {
    color: #000 !important;
  }


  .dark .popup button.light-modal-button.got-it-button.new-visitor-button {
    background-color: transparent !important;
    color: #529fcc !important;
  }


}

@media screen and (max-width:1199.5px) {
  .mute-button{
    position: static;
    /* top: 560px !important; */
    background-color: transparent !important;
    border: none !important;
    /* right:10px; */
    height:50px;
    width: 45px;
    padding-left: 0;
    margin-left: -5px !important;
  }

  /* .justify-content-end.navbar-collapse.collapse.show{
    height: 526px;
  } */
  button.info-sound-click-button::after {
    content: "x";
  }

  span.notification-more::after {
    content: "x";
  }

  .notification-more::after {
    content: "x";
  }

  button.info-sound-click-button:hover::after{
    color:rgb(0, 0, 0) !important;
    transition: all 0.15s ease-in;


  }

  .dark button.info-sound-click-button:hover::after{
    color: gold !important;
    transition: all 0.15s ease-in;


  }

  .old button.info-sound-click-button::after{
    color:lightslategray !important;
  }

  .old button.info-sound-click-button:hover::after{
    color:rgb(9, 44, 80) !important;
    transition: all 0.15s ease-in;
  }

  .old .close-notification-container{
    background-color: transparent !important;
  }
  .dark .close-notification-container,
  .close-notification-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: 10px;
    padding: 0px;
    background-color: transparent;
  }

  a.notification-more-link {
    color: #fff !important;
  }

  button.info-sound-click-button {
    color: #fff !important;
    left: 4%;
    font-size: 18px;

  }

  .dark button.info-sound-click-button {
    color: #529fcc !important;
    left: 4%;
    font-size: 18px;

  }

  .notification-button {
    padding-left: 0 !important;
  }

  div.notification {
    display: block;
    border-radius: 0px;
    text-align: center;
    width: 80%;
    height: fit-content;
    padding: 30px 20px 20px 20px;
    margin: 0 auto;
    position: sticky;
    bottom: -10px;
    z-index: 40;
    color: #000;
    background-color: #529fcc;
    border-top: 1px solid #000;

  }

  .dark div.notification {
    border-radius: 0px;
    display: block;
    text-align: center;
    width: 80%;
    height: fit-content;
    padding: 20px;
    margin: 0 auto;
    position: sticky;
    bottom: -10px;
    z-index: 40;
    color: whitesmoke;
    background-color: #000;
    border-top: 1px solid whitesmoke;

  }

  .fa-globe {
    padding-left: 0px;
  }

  button.toggle_btn {
    padding-left: 0px;
    padding-top: 10px;
    width: 24px;
    height: 44px !important;
  }

  div.switcher {
    padding-left: 0;
  }

  .moon {
    font-size: 24px;
    margin-left: 0;
  }

  .sun {
    font-size: 24px;
    margin-left: 0;
  }
}

@media screen and (min-width:992px) and (max-width:1073px) {
  .nav-link:last-child {
    padding-right: 0 !important;
  }
}



nav.page-header {
  width: 100%;
}

@media (prefers-reduced-motion: reduce) {

  .reduced-motion .about-download:hover,
  .reduced-motion .about-git:hover,
  .dark .about-download:hover{
    transition: none !important;
    translate: 0px 0px !important;
}

.fa-caret-right{
  transition: none !important;

}

  .dark .aboutAnimation,
  .system .aboutAnimation,
  .old .aboutAnimation,
  .reduced-motion .aboutAnimation {

    animation: none !important;
    background-image: #424242 !important;
  }

  .aboutAnimation {
    animation: none !important;
    background: url('./../../about-view/img/bg.jpg');
  }

  .logo-pulse,
  .reduced-motion .logo-pulse,
  .dark .logo-pulse,
  .system .logo-pulse,
  .old .logo-pulse
  {
    animation: none !important;
    border-radius: 50%;
    width: 20em;
    
  }

  .fa-envelope:hover,
  .fa-skype:hover,
  .fa-linkedin:hover,
  .fa-twitter:hover,
  .fa-github:hover,
  .fa-instagram:hover,
  .fa-arrow-up-right:hover,
  .fa-medium:hover,
  .fa-dev:hover {
    transition: none !important;
    translate: none !important;
    color: #292929 !important;
  }

  .dark .fa-envelope:hover,
  .dark .fa-skype:hover,
  .dark .fa-linkedin:hover,
  .dark .fa-twitter:hover,
  .dark .fa-github:hover,
  .dark .fa-instagram:hover,
  .dark .fa-arrow-up-right:hover,
  .dark .fa-medium:hover,
  .dark .fa-dev:hover {
    transition: none !important;
    translate: none !important;
  }

  .reduced-motion .fa-envelope:hover,
  .reduced-motion .fa-skype:hover,
  .reduced-motion .fa-linkedin:hover,
  .reduced-motion .fa-twitter:hover,
  .reduced-motion .fa-github:hover,
  .reduced-motion .fa-instagram:hover,
  .reduced-motion .fa-arrow-up-right:hover,
  .reduced-motion .fa-medium:hover,
  .reduced-motion .fa-dev:hover {
    transition: none !important;
    translate: none !important;
  }

  .system .fa-envelope:hover,
  .system .fa-skype:hover,
  .system .fa-linkedin:hover,
  .system .fa-twitter:hover,
  .system .fa-github:hover,
  .system .fa-instagram:hover,
  .system .fa-arrow-up-right:hover,
  .system .fa-medium:hover,
  .system .fa-dev:hover {
    transition: none !important;
    translate: none !important;
  }

  .old .fa-envelope:hover,
  .old .fa-skype:hover,
  .old .fa-linkedin:hover,
  .old .fa-twitter:hover,
  .old .fa-github:hover,
  .old .fa-instagram:hover,
  .old .fa-arrow-up-right:hover,
  .old .fa-medium:hover,
  .old .fa-dev:hover {
    transition: none !important;
    translate: none !important;
  }

  .social-container {
    /* left:0; */
    left: -37px;
  }

  .social-container:hover {
    /* left:0; */
    opacity: 1;
    transition: none !important;
  }

  .nav-cases:hover {
    transition: none !important;
    padding-left: 0;
  }

  .text-light, #collapsible-nav-dropdown:hover, .lang-active:hover{
    transition: none !important;

  }

  .projects-carousel-caption:hover,
  .projects-carousel-caption:active {
    transition: none !important;

  }

  .icon-style,
  .dark .icon-style,
  .system .icon-style,
  .old .icon-style,
  .reduced-motion .icon-style {
    animation: none !important;
  }

  .r3play-carousel-caption:hover {

    transition: none !important;

  }

  p.skills-paragraphs span:hover {
    color: #fff !important;
    opacity: 1 !important;
    transition: none !important;
  }


  .carousel-caption:hover,
  .carousel-caption:active {
    transition: none !important;
  }


  .logo:hover {

    transform: none !important;

    transition: none !important;
  }


  .picture-404, .reduced-motion .picture-404,
  .system .picture-404, .old .picture-404,
  .dark .picture-404
  {
    animation: none !important;
  }

  .old .picture-404{
    content: url('./../img/reduced-old-404.png') !important;
  }
  
  .modal-body.motion-modal-body{
    margin-right: 90px !important;
     }

 

  .main-footer .sidebar-toggler,
  .main-footer.dark .sidebar-toggler,
  .main-footer.old .sidebar-toggler,
  .main-footer.system .sidebar-toggler,
  .main-footer.reduced-motion .sidebar-toggler,
  .footer-404.old .sidebar-toggler,
  .footer-404 .sidebar-toggler,
  .footer-404.system .sidebar-toggler,
  .footer-404.reduced-motion .sidebar-toggler,
  .footer-404.dark .sidebar-toggler {
    animation: none !important;
  }

  .sidebar-pulse,
  .dark .sidebar-pulse,
  .old .sidebar-pulse,
  .system .sidebar-pulse,
  .reduced-motion .sidebar-pulse{
  animation: none !important;

}

.profile-picture, .reduced-motion .profile-picture {
  animation: none !important;
}

.old .profile-picture{
  content: url('./../img/prefers-old-profile-picture.jpg');
}
.reduced-motion div.modal-dialog, .reduced-motion div.modal-content, div.reduced-motion .modal-body, div.dark.reduced-motion .modal-body{
  background-color: #000000 !important;
  color: whitesmoke;
}

}

@media screen and (min-width:992px) and (max-width:1199px) {
  .navbar-collapse.show {
    padding-left: 28px !important;
  }
}

@media screen and (min-width:1199px) and (max-width:1254px) {
  a.nav-link.pe-4.text-light.bell-btn {
    padding-right: 0 !important;
  }
}

@media screen and (min-width:1199.5px) and (max-width:1399.5px) {
  .navigation.container {
    flex-direction: column;
  }
}

@media screen and (max-width:500px) {
  div.motion-modal-favorite  .modal-dialog{
    bottom: 46px !important;
  }
  .modal-body.motion-modal-body{
    margin-right: 70px !important;
  }
}

